import Header from 'components/Header'
import { useAuth } from 'clients/auth.client'
import { useGlobalModal } from 'modals/GlobalModal'
import cc from 'classcat'
import Image from 'next/legacy/image'
import { ReactElement, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import ProfilePicture from 'components/ProfilePicture'
import { signOut } from 'next-auth/react'
import withRouterReady from 'hocs/withRouterReady'
import { useTranslation } from 'next-i18next'
import { useGlobalStore } from 'stores/global.store'
import { LinkAuthLogin } from 'components/LinkAuth'
import Link from 'next/link'
import { env } from 'env/client.mjs'
import useTidio from 'hooks/useTidio'
import { SUPPORTED_LANGUAGES } from 'config/constants'

const LogoIconoPay = '/assets/logo-iconopay.png'
const LogoMissUniverseAntioquia = '/assets/logo-miss-universe-antioquia.png'
const LogoInfluexer = '/assets/logo_grey.svg'

const PageLayout = ({ children, pageProps, extra, $contentAlign }: any) => {
  const modal = useGlobalModal()
  const { isAuthenticated, impersonator } = useAuth()
  const [displayHeader, setDisplayHeader] = useState(false)

  // First render, then we display (re-render with maybe header)
  useEffect(() => {
    setDisplayHeader(true)
  }, [])

  return (
    <div
      className={cc(['flex flex-col min-h-full', { xhidden: modal.isOpen }])}
    >
      {displayHeader && isAuthenticated && <Header />}

      <main
        className={cc([
          'flex-grow flex flex-col relative',
          {
            'justify-center': $contentAlign === 'center',
          },
        ])}
      >
        {children}
      </main>

      <Footer pageProps={pageProps} extra={extra} />

      {impersonator && <Impersonator />}
    </div>
  )
}

export default PageLayout

const Footer = withRouterReady(
  ({ pageProps, extra }: any) => {
    const { isAuthenticated } = useAuth()
    const router = useRouter()
    const { username } = router.query
    const { t } = useTranslation()
    const { platform } = useGlobalStore()
    const pagePropsPlatform: string | null = pageProps?.platform ?? null
    const tidio = useTidio()

    return (
      <footer className="px-4 py-6 text-gray-600 text-sm text-center">
        {username && (
          <div>
            <span className="text-xs">Powered by:</span>
          </div>
        )}

        {isAuthenticated ? (
          <>
            {platform === 'iconopay' ||
            (pagePropsPlatform && platform !== pagePropsPlatform) ? (
              <PlatformInfxPartner partner={pagePropsPlatform ?? platform} />
            ) : (
              <PlatformInfx />
            )}
          </>
        ) : (
          <>
            {platform === 'influexer' && <PlatformInfx />}
            {platform === 'iconopay' && <PlatformIconoPay />}
          </>
        )}

        {/* Change Language */}
        <div className="py-1">
          <select
            className="bg-gray-100 border-0 text-gray-700 p-2 cursor-pointer"
            value={router.locale}
            onChange={(e) => {
              const { value } = e.target
              const currentUrl = window.location.href
              const newUrl = new URL(
                `/${value}${router.asPath}`,
                currentUrl,
              ).toString()

              if (newUrl !== currentUrl) {
                return (window.location.href = newUrl)
              }
            }}
          >
            {SUPPORTED_LANGUAGES.map(({ locale, flag, text }) => (
              <option key={`force-language-${locale}`} value={locale}>
                {flag} {text}
              </option>
            ))}
          </select>
        </div>

        <div className="md:inline-flex md:space-x-6">
          {/* Customer Servier */}
          {env.NEXT_PUBLIC_TIDIO_KEY && (
            <button
              type="button"
              className="text-action-fail font-bold"
              onClick={() => {
                tidio.open()
              }}
            >
              {t('footer:havingTrouble')}
            </button>
          )}

          {/* Copyright */}
          <div>
            {new Date().getFullYear()} {t('footer:copyright')}
          </div>

          {/* Terms */}
          <div>
            <Link
              href="/terms-and-conditions"
              data-testid="termsAndConditions"
              className="text-primary"
            >
              {t('common:termsAndConditions')}
            </Link>{' '}
            |{' '}
            <Link
              href="/privacy-policy"
              data-testid="privacyPolicy"
              className="text-primary"
            >
              {t('common:privacyPolicy')}
            </Link>
          </div>
        </div>

        {!isAuthenticated && !extra?.isAuthPage && (
          <div className="pt-2">
            Are you an influencer?{'   '}
            <span className="text-primary cursor-pointer font-bold">
              <LinkAuthLogin>
                <a>Login or Sign Up</a>
              </LinkAuthLogin>{' '}
              now
            </span>
          </div>
        )}
      </footer>
    )
  },
  { hideLoading: true },
)

const PlatformInfx = () => {
  return (
    <div>
      <Image
        src={LogoInfluexer}
        width={120}
        height={21}
        className="mx-auto mb-1"
        alt="Influexer"
        data-testid="logo-influexer-gray"
      />
    </div>
  )
}

const PlatformIconoPay = () => {
  return (
    <div className="grayscale opacity-80">
      <Image
        src={LogoIconoPay}
        width="98"
        height="21"
        alt="IconoPay"
        data-testid="logo-iconopay-gray"
      />
    </div>
  )
}

const PlatformMissUniverseAntioquia = () => {
  return (
    <div>
      <Image
        src={LogoMissUniverseAntioquia}
        width="120"
        height="24"
        alt="Miss Universe Antioquia"
        data-testid="logo-miss-universe-antioquia-gray"
      />
    </div>
  )
}

const PlatformInfxPartner = ({ partner }: { partner: string }) => {
  let PartnerLogo: ReactElement | null = null

  switch (partner) {
    case 'iconopay':
      PartnerLogo = <PlatformIconoPay />
      break
    case 'miss-universe-antioquia':
      PartnerLogo = <PlatformMissUniverseAntioquia />
      break
  }

  return (
    <div
      className="inline-flex py-2"
      data-testid="logo-influexer-and-iconopay-gray"
    >
      {PartnerLogo && (
        <>
          {PartnerLogo}
          <span className="font-bold px-2">+</span>
        </>
      )}

      <PlatformInfx />
    </div>
  )
}

const Impersonator = () => {
  const { impersonator } = useAuth()

  if (!impersonator) {
    return null
  }

  return (
    <button
      type="button"
      className="rounded-full z-12 fixed bottom-0 left-0 my-5 shadow-lg opacity-95 md:m-3 md:hover:scale-110 md:ease-in-out md:duration-300"
      onClick={() => {
        signOut({
          callbackUrl: `/auth/login?impersonate=${impersonator.token}`,
        })
      }}
    >
      <div className="rounded-full overflow-hidden absolute inset-0 bg-white opacity-75 backdrop-filter backdrop-blur" />

      <div className="rounded-full relative bg-primary text-white p-2 m-1 flex items-center">
        <div>
          <ProfilePicture
            userID={impersonator.id}
            image={impersonator.picture}
            size="sm"
          />
        </div>

        <div className="pl-3 pr-2 text-left text-xs">
          <p className="opacity-70">Log back in</p>
          <strong>{impersonator.publicName}</strong>
          <p className="opacity-70">@{impersonator.username}</p>
        </div>
      </div>
    </button>
  )
}
